import clsx from 'clsx';

import styles from './SidebarWrapper.module.scss';

interface ISidebarWrapperProps {
  className?: string;
  children: React.ReactNode;
}

export const SidebarWrapper = ({
  className,
  children,
}: ISidebarWrapperProps) => {
  return (
    <section className={clsx(styles.block, className)}>{children}</section>
  );
};
